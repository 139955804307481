import React, { Component } from "react";
import EnConstruccio from "../components/EnConstruccio";

class Fotografies extends Component {
	render() {
		return (<>
			<section>
				<h2>Fotografies</h2>

				<EnConstruccio />
			</section>
		</>);
	}
}

export default Fotografies;
