import React, { Component } from "react";

class EnConstruccio extends Component {
	render() {
		return (<>
			<section className="in-construction">
				<h2>Secció en construcció</h2>
				<h4>Consulteu la pàgina més endavant</h4>
			</section>
		</>);
	}
}

export default EnConstruccio;
