import React, { Component } from "react";

class PartsCastell extends Component {
	render() {
		return (<>
			<section>
				<h2>Parts d'un castell</h2>
			</section>
		</>);
	}
}

export default PartsCastell;
